@import 'vars';

.main-grid {
  .contact-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    margin: 50px 0 60px;
    color: $light-color;
    @media all and (max-width: $firstBreakPoint) {
      font-size: 16px;
    }
    @media all and (max-width: $secondBreakPoint) {
      font-size: 18px;
      grid-column-start: 2;
      grid-column-end: span 3;
      padding-bottom: 0;
      margin: 10px 0;
    }
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
    * {
      text-align: center;
    }
    .icon {
      width: 40px;
      height: 40px;
      @media screen and (max-width: 600px) {
        width: 30px;
        height: 30px;
      }
      @media all and (max-width: $secondBreakPoint) {
        width: 20px;
        height: 20px;
      }
      background-repeat: no-repeat;
      margin-bottom: 16px;
    }
    span {
      font-family: 'Sweet Sans Pro Bold';
      font-size: 1.4rem;
      @media all and (max-width: $secondBreakPoint) {
        font-size: 1.4rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 1.2rem;
      }
      margin-bottom: 6px;
    }
    .tile-content {
      font-family: 'Sweet Sans Pro Light';
    }
    a {
      text-decoration: none;
      color: $light-color;
    }
  }
  .email-tile {
    .email-icon {
      background-image: url('icons/email-2-2.png');
      background-size: contain;
    }
    @media all and (max-width: $secondBreakPoint) {
      padding-top: 0px;
    }
  }
  .address-tile {
    .home-icon {
      background-image: url('icons/home-2-2.png');
      background-size: contain;
    }
  }
  .phone-number-tile {
    .phone-icon {
      background-image: url('icons/phone-2-2.png');
      background-size: contain;
    }
    @media all and (max-width: $secondBreakPoint) {
      margin-bottom: 20px;
    }
  }
}
