@import '../vars';
.main-grid {
  .top-photos-contact.top-left-photo-tile {
    background-image: url('../images/kontakt-left.png');
    background-position-y: top;
  }
  .photo-tile.top-photos-contact.top-mid-photo-tile {
    background-image: url('../images/kontakt-center.png');
    background-position-y: 100%;
  }
  .top-photos-contact.top-right-photo-tile {
    background-image: url('../images/kontakt-right.png');
  }
  .photo-tile.bottom-photos-contact.bottom-left-photo-tile {
    background-image: url('../images/contact-bottom-left.png');
    background-position-y: 40%;
    background-size: cover;
  }
  .photo-tile.bottom-photos-contact.bottom-right-photo-tile {
    background-image: url('../images/contact-bottom-right.png');
    background-position-y: 40%;
  }
}
.main-content-text {
  a {
    color: $base-color;
    text-decoration: none;
    &:hover {
      color: $light-color;
    }
  }
}
main {
  .contact-article {
    .main-content-text {
      p {
        text-align: right;
      }
    }
  }
}
