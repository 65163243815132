@import '../vars';

.main-grid {
  .top-photos-offer.top-left-photo-tile {
    background-image: url('../images/honorarium-left.png');
    background-position-y: top;
  }
  .photo-tile.top-photos-offer.top-mid-photo-tile {
    background-image: url('../images/honorarium-middle.png');
    background-position-y: 80%;
  }
  .top-photos-offer.top-right-photo-tile {
    background-image: url('../images/honorarium-right.png');
  }
  .photo-tile.bottom-photos-offer.bottom-left-photo-tile {
    //background-position-y: 40%;
    //background-position-x: 30%;
    background-size: cover;
  }
  .photo-tile.bottom-photos-offer.bottom-right-photo-tile {
    background-position-y: 40%;
    background-position-x: 10%;
  }
}

.offer-article {
  gap: 20px;
}
