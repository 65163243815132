@import '../vars';

.main-grid {
  main {
    header.subsite-header {
      h2.blog {
        font-size: 120px;
        line-height: 80px;
      }
      .lawyer-signature {
        p {
          font-size: 18px !important;
        }
      }
    }
  }
  grid-template-columns: 1fr 1fr;
  .blog-article {
    display: grid;
    grid-template-columns: 300px 1fr;
    flex-direction: column;
    h3.blog-title {
      grid-column-start: 2;
      grid-column-end: 3;
      text-align: left;
      font-size: 30px;
      padding-bottom: 12px;
      padding-left: 20px;
    }
    .blog-image-container {
      grid-column-start: 1;
      grid-column-end: 2;
      .blog-image {
        width: 300px;
        padding-bottom: 100%;
        background-size: cover;
      }
    }
    .blog-content {
      grid-column-start: 2;
      grid-column-end: 3;
      flex: 1;
      h2,
      h3,
      h4:first-child {
        margin-top: -6px;
      }
      h2 {
        font-size: 26px;
        color: $base-color;
        line-height: 36px;
      }
      p {
        text-align: justify;
        font-size: 18px;
        line-height: 24px;
      }
      strong {
        font-family: 'Sweet Sans Pro Medium';
      }
      margin: 0 auto;
      padding: 0 20px;
    }
    .blog-image-and-content {
    }
  }
}
