body {
  margin: 0;
  font-family: 'Sweet Sans Pro', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

@font-face {
  font-family: "Sweet Sans Pro";
  src: local("Sweet Sans Pro"),
  url("./fonts/SweetSansProLight.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Sweet Sans Pro Medium";
  src: local("Sweet Sans Pro Medium"),
  url("./fonts/SweetSansProMedium.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Sweet Sans Pro Light";
  src: local("Sweet Sans Pro Light"),
  url("./fonts/SweetSansProLight.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Sweet Sans Pro Bold";
  src: local("Sweet Sans Pro Bold"),
  url("./fonts/SweetSansProBold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Engravers";
  src: local("Engravers"),
  url("./fonts/Engravers-Gothic-BT-Font.ttf") format("truetype");
  font-weight: bold;
}
