@import '../vars';
.root {
  .main-grid {
    grid-template-rows: auto;
    main {
      .blogs-article {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1rem;
        @media screen and (max-width: $secondBreakPoint) {
          grid-template-columns: 1fr;
        }
        a {
          text-decoration: none;
          display: flex;
          flex-direction: column;
          .blog-list-item {
            cursor: pointer;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: $secondBreakPoint) {
              flex-direction: row;
            }
            @media screen and (max-width: $thirdBreakPoint) {
              flex-direction: column;
            }
          }
          .blog-list-item .image-container {
            @media screen and (max-width: $secondBreakPoint) {
              width: 20%;
            }
            @media screen and (max-width: $thirdBreakPoint) {
              width: 100%;
            }
            .image {
              background-size: cover;
              background-position: center;
              padding-bottom: 100%;
              @media screen and (max-width: $secondBreakPoint) {
                width: 100%;
                padding-bottom: 100%;
              }
            }
          }
          .blog-list-item .content {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            @media screen and (max-width: $secondBreakPoint) {
            }
            flex-grow: 1;
            background-color: $very-light;
            padding: 12px;
            h2 {
              flex-grow: 1;
              margin-top: 0;
              text-align: center;
              font-family: 'Sweet Sans Pro Medium';
              @media screen and (max-width: $secondBreakPoint) {
                flex-grow: 1;
              }
            }
            .date {
              @media screen and (max-width: $secondBreakPoint) {
                align-self: flex-end;
                justify-self: flex-end;
              }
              font-size: 14px;
              text-align: center;
            }
          }
        }
        a:hover {
          color: $light-color;
        }
        a:visited {
          color: $light-color;
        }
      }
    }
  }
}
