@import 'vars';
.main-content-header {
  display: flex;
  flex-direction: column;
  color: $base-color;
  text-transform: uppercase;
  font-size: 26px;
  span:first-child {
    font-family: 'Sweet Sans Pro Light';
  }
  span:last-child {
    font-family: 'Sweet Sans Pro Medium';
  }
}

main {
  article {
    margin-top: 10px;
    display: grid;
    grid-template-columns: max-content 1fr;
    flex-direction: row;
    gap: 0px;
    @media screen and (max-width: $secondBreakPoint) {
      display: flex;
      flex-direction: column;
    }
    h3.main-content-header {
      font-size: 22px;
      @media screen and (max-width: $secondBreakPoint) {
        font-size: 18px;
      }
      margin-top: 2px;
      margin-bottom: 2px;
    }
    .main-content-text {
      flex-grow: 2;
      flex-basis: 0;
      line-height: 30px;
      p:first-child {
        margin-top: 0;
      }
      p {
        text-align: justify;
        line-height: 30px;
        font-size: 18px;
      }
    }
  }
}
