@import 'vars';

.App {
  text-align: center;
}

html {
  font-size: 12px;
}

h3 {
  font-family: 'Sweet Sans Pro Medium';
  font-size: 26px;
  color: $base-color;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.root {
  overflow: hidden;
  .top-line {
    background-color: $base-color;
    height: 0.8rem;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 26px;
    padding-top: 26px;
    h1 {
      font-weight: normal;
    }
    h1:first-child {
      font-size: 26px;
      border-bottom: 1px solid rgba(gray, 0.5);
      margin: 0;
      padding-bottom: 10px;
      text-transform: uppercase;
    }
    h1:last-child {
      margin-top: 20px;
      font-size: 1.5rem;
    }
  }
  nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    border-bottom: 5px solid $very-light;
    border-top: 5px solid $very-light;
    padding: 16px 0;
    @media screen and (max-width: $secondBreakPoint) {
      padding: 8px 0;
    }
    margin-bottom: 10px;
    text-transform: uppercase;
    ul {
      padding: 6px 0 0;
      li {
        font-family: 'Sweet Sans Pro Medium';
        display: inline-block;
        font-size: 1.2rem;
        font-weight: bolder;
        cursor: pointer;
        margin: 0 16px;
        a {
          color: $base-color;
          text-decoration: none;
        }
      }
    }
    .btn-mobile-nav {
      border-width: 0px;
      background-color: transparent;
      display: none;

      @media all and (max-width: $secondBreakPoint) {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        .hide {
          display: none;
        }
        ion-icon[name='menu-outline'] {
        }
      }

      ion-icon {
        color: black;
        font-size: 1.8rem;
      }
    }
  }
  .main-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 380px 380px 380px 1fr;
    grid-template-rows: 380px;
    @media all and (max-width: $firstBreakPoint) {
      grid-template-columns: 1fr 240px 240px 240px 1fr;
      grid-template-rows: 260px;
    }
    @media all and (max-width: $secondBreakPoint) {
      grid-template-columns: 15px 1fr 1fr 1fr 15px;
      grid-template-rows: min-content;
    }
    .photo-tile {
      padding-bottom: 100%;
      background-position: center;
      background-size: cover;
    }
    iframe {
      border: 0;
      width: 100%;
      padding-bottom: 0%;
      grid-column-start: 3;
      grid-column-end: 4;
      filter: grayscale(100%);
    }
    .google-maps-tile {
      padding-bottom: 100%;
      position: relative;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      //.pin-map {
      //  background-image: url('images/pin-map.png');
      //  background-size: cover;
      //  z-index: 1001;
      //  position: absolute;
      //  top: calc(60% - 60px);
      //  left: calc(60% - 80px);
      //  width: 60px;
      //  height: 80px;
      //}
      .google-maps-info-tile {
        cursor: pointer;
        margin: 10px;
        width: calc(100% - 10px - 10px);
        display: flex;
        padding: 6px 0px;
        justify-content: center;
        align-items: center;
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
        @media screen and (max-width: $secondBreakPoint) {
        }
        position: absolute;
        background-color: white;
        border: 1px solid $very-light;
        a {
          p.desktop {
            display: none;
            @media screen and (min-width: $secondBreakPoint) {
              display: block;
            }
          }
          p.mobile {
            margin-top: 0;
            margin-bottom: 0;
            line-height: 14px;
            display: block;
            text-align: center;
            @media screen and (min-width: $secondBreakPoint) {
              display: none;
            }
          }
        }
        p {
          @media screen and (max-width: $secondBreakPoint) {
            margin-top: 0;
            margin-bottom: 0;
            word-break: break-word;
            font-size: 12px;
            line-height: 14px;
          }
          font-size: 16px;
          margin: 8px;
          color: $light-color;
          font-family: 'Sweet Sans Pro Medium';
          text-decoration: underline;
          line-height: 22px;
        }
      }
    }
    .photo-tile.bottom-left-photo-tile {
      background-image: url('images/bottom-left.png');
      background-position-y: -125px;
      @media screen and (max-width: $firstBreakPoint) {
        background-position-y: -80px;
      }
      @media screen and (max-width: $secondBreakPoint) {
        background-position-y: 100%;
      }
    }
    .photo-tile.bottom-right-photo-tile {
      background-image: url('images/bottom-right.png');
      background-position-y: 100%;
    }
    .dark-side-block {
      background-color: $light-color;
    }
    .light-side-block {
      background-color: $very-light;
    }
    main {
      grid-column-start: 2;
      grid-column-end: 5;
      header.subsite-header {
        position: relative;
        display: flex;
        align-items: center;
        height: max-content;
        box-sizing: border-box;
        @media screen and (max-width: $secondBreakPoint) {
          height: 20vw;
        }
        flex-grow: 1;
        flex-basis: 0;
        margin-top: 0px;
        margin-bottom: 0px;
        h2 {
          font-family: 'DM Serif Display';
          position: relative;
          font-weight: normal;
          font-size: 180px;
          line-height: 120px;
          margin-top: 10px;
          margin-bottom: 20px;
          color: $very-light;
          text-transform: uppercase;
          @media screen and (max-width: $firstBreakPoint) {
            margin-top: 20px;
            font-size: 118px;
            line-height: 80px;
          }
          @media screen and (max-width: $secondBreakPoint) {
            font-size: 53px;
            //margin-top: 0px;
            //margin-bottom: 10px;
          }
          //@media screen and (max-width: $firstBreakPoint) {
          //  font-size: 12vw;
          //}
        }
        h2.main {
          font-size: 220px;
          line-height: 140px;
          //@media screen and (max-width: $firstBreakPoint) {
          //  font-size: 12vw;
          //}
        }
        //h2.contact {
        //  font-size: 12vw;
        //}
        //h2.offer {
        //  font-size: 118px;
        //}
        //h2.services {
        //  font-size: 18vw;
        //}
        //h2.about {
        //  font-size: 25vw;
        //}
        //@media all and (max-width: $secondBreakPoint) {
        //  margin: 0 10px;
        //}
        .lawyer-signature {
          position: absolute;
          //top: calc(50%);
          transform: translate(0, 10%);
          right: 20px;
          text-align: right;
          display: flex;
          flex-direction: column;
          justify-content: center;
          p {
            font-family: 'Sweet Sans Pro', 'Roboto', sans-serif;
            font-size: 2.3rem;
            @media screen and (max-width: $secondBreakPoint) {
              font-size: 3.8vw;
            }
            margin: 0;
            color: $base-color;
          }
        }
      }
    }
    .left-margin {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .right-margin {
      grid-column-start: -2;
      grid-column-end: -1;
    }
  }
  footer {
    background-color: $base-color;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0 10px;
    ul {
      display: none;
      width: max-content;
      padding-left: 0;
      li {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }
}

//@media all and (max-width: $firstBreakPoint) {
//  .root {
//    .main-grid {
//      grid-template-columns: 1fr 180px 180px 180px 1fr;
//      grid-template-rows: 180px;
//      .contact-tile {
//        grid-column-start: 2;
//        grid-column-end: span 3;
//        padding-bottom: 0;
//        height: 120px;
//      }
//      .email-tile {
//        height: 90px;
//      }
//    }
//  }
//}

@media all and (max-width: $secondBreakPoint) {
  .root {
    .main-grid {
      main {
        .subsite-header {
          div:first-child {
            margin-left: 10px;
            p {
              font-size: 30vw;
            }
          }
        }
      }
    }
    .header {
      h1:first-child {
        font-size: 1.5em;
      }
      h1:last-child {
        font-size: 1.4em;
      }
    }
    nav {
      position: relative;
      justify-content: right;
      ul {
        z-index: 1000;
        transition: all 330ms ease-in-out;
        background-color: rgba(255, 255, 255, 0.88);
        gap: 20px;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 50px;
        margin-top: 0;
        padding-top: 30px;
        position: absolute;
        display: flex;
        align-items: center;
        font-size: 2rem;
        flex-direction: column;
      }
      ul.hide-menu {
        left: 100vw;
      }
    }
  }
}
