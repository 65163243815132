@import '../vars';

.main-grid {
  .top-photos-main.top-left-photo-tile {
    background-image: url('../images/image-left-top-main.jpeg');
  }
  .photo-tile.top-photos-main.top-mid-photo-tile {
    background-image: url('../images/o-mnie-middle.png');
    background-position-y: 40%;
  }
  .top-photos-main.top-right-photo-tile {
    background-image: url('../images/image-right-top-main.jpeg');
  }
  .photo-tile.bottom-photos-main.bottom-left-photo-tile {
    background-image: url('../images/main-bottom-left.png');
    background-position-y: 40%;
    background-size: cover;
  }
  .photo-tile.bottom-photos-main.bottom-right-photo-tile {
    background-image: url('../images/main-bottom-right.png');
    background-position-y: 40%;
  }
}

.hide {
  display: none;
}

.main-article {
  gap: 20px;
}
