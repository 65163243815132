@import '../vars';
.main-grid {
  .top-photos-services.top-left-photo-tile {
    background-image: url('../images/zakres-uslug-left.png');
    background-position-y: bottom;
  }
  .photo-tile.top-photos-services.top-mid-photo-tile {
    background-image: url('../images/uslugi-middle.png');
    background-position-y: 70%;
  }
  .top-photos-services.top-right-photo-tile {
    background-image: url('../images/zakres-uslug-right.png');
  }
}

.services-article {
  gap: 20px;
}
