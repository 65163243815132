.main-grid {
  .top-photos-about.top-left-photo-tile {
    background-image: url('../images/o-mnie-left.png');
    background-position-y: top;
  }
  .top-photos-about.top-mid-photo-tile {
    background-image: url('../images/main-middle-closer.png');
    background-position-y: -0px;
  }
  .top-photos-about.top-right-photo-tile {
    background-image: url('../images/o-mnie-right-2.png');
  }
  .photo-tile.bottom-photos-about.bottom-left-photo-tile {
    background-image: url('../images/about-bottom-left.png');
    background-position-y: 40%;
    background-size: cover;
  }
  .photo-tile.bottom-photos-about.bottom-right-photo-tile {
    background-image: url('../images/about-bottom-right.png');
    background-position-y: 40%;
  }
  .about-article {
    gap: 12px;
    .about-photo {
      background-image: url('../images/4.png');
      background-size: cover;
      background-position-y: -30px;
      height: 400px;
      width: 300px;
      @media all and (max-width: 620px) {
        width: 100%;
        height: 120vw;
      }
      @media all and (max-width: 900px) {
        margin: 0 auto;
      }
      float: left;
      margin-top: 10px;
      margin-right: 0;
      @media all and (max-width: 620px) {
        float: right;
      }
    }
    .main-content-text {
      @media all and (max-width: 900px) {
        margin: 0 auto;
        padding: 0;
      }
    }
  }
}
