$base-color: #002f5d;
$light-color: rgba(#002f5d, 0.7);
$very-light: rgba(138, 138, 205, 0.12);
$light-black-color: #181818;
$firstBreakPoint: 1200px;
$secondBreakPoint: 800px;
$thirdBreakPoint: 500px;
:export {
  firstBreakPoint: $firstBreakPoint;
  secondBreakPoint: $secondBreakPoint;
}
